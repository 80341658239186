import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import * as routes from 'constants/routes';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';

const useStyles = makeStyles((theme) => ({
  signoutWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 'auto',
    height: '70px',
    margin: '70px 0 0',
  },
  signout: {
    position: 'absolute',
    bottom: '30px',
    left: '50%',
    marginLeft: '-100px',
    border: '1px solid ' + theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    fontWeight: '400',
    fontSize: '0.9375rem',
    lineHeight: '1.75',
    borderRadius: '1px',
    textAlign: 'center',
    textTransform: 'none',
    padding: '7px 21px',
    minWidth: '200px',

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  logoutIcon: {
    marginLeft: '5px',
    fontSize: '1em',
  },
}));
const Logout = () => {
  const authUser = useSelector((state) => state.sessionState.authUser);
  const classes = useStyles();
  const history = useHistory();
  const signOut = () => {
    history.push(routes.SIGN_OUT);
  };
  return (
    <>
      {authUser?.uid && (
        <div className={classes.signoutWrapper}>
          <Button
            aria-label="back"
            className={classes.signout}
            onClick={signOut}
            data-id="sign-out-btn"
          >
            Sign Out
            <LogoutIcon className={classes.logoutIcon} />
          </Button>
        </div>
      )}
    </>
  );
};

export default Logout;
